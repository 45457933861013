import React, {useState, useEffect} from "react";
import {
  ConfigurationType,
  LockTypeConfigurationListResponse,
  LockTypeConfigurations,
  UnitAccessConfiguration
} from "@common/typing";
import { AccessesService } from "@common/units-api";
import { authManager } from '@common/authentication';
import { useToasts } from "react-toast-notifications";
import _ from "lodash";
import { Icon, Required, ThemeProvider, Tooltip } from "@vacasa/react-components-lib";
import _default from "@vacasa/react-components-lib/lib/themes/default";
import AccessDetailStyles from "../AccessDetailsComponent.module.scss";

interface LockConfigurationsProps {
  accessConfigurations: Array<UnitAccessConfiguration>;
  accessId: number;
  lockTypeId: number;
  setAccessConfigurations: Function;
  addRequiredFields: Function;
  protectedConfigValues: {[key:string] : boolean};
  setProtectedConfigValues: Function;
}

export const LockConfigurationsComponent: React.FunctionComponent<LockConfigurationsProps> = (props) => {
  const {accessConfigurations, accessId, lockTypeId, setAccessConfigurations, addRequiredFields, protectedConfigValues, setProtectedConfigValues } = props;
  const [switchState, setSwitchState] = useState(true);
  const [configurations, setConfigurations] = useState<Array<LockTypeConfigurations>>([]);
  const [configurationTypes, setConfigurationTypes] = useState<Array<ConfigurationType>>([]);
  const [inputState, setInputState] : [{[key:string] : any},Function] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const unitService = new AccessesService(authManager.getJwt());
  const info = authManager.getInfoFromAdmin<{ user: string; unitId: number; }>();
  const { addToast } = useToasts();
  let configurationsRequired = false;
  const eye = (<ThemeProvider theme={_default}><Tooltip message={"Mask instruction codes"} placement={"bottom"}><span><Icon.Eye className="pointer" name="info" width={18} height={18} /></span></Tooltip></ThemeProvider>);
  const eyeOff = (<ThemeProvider theme={_default}><Tooltip message={"Reveal instruction codes"} placement={"bottom"}><span><Icon.EyeOff className="pointer" name="info" width={18} height={18} /></span></Tooltip></ThemeProvider>);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const configurationsResponse: LockTypeConfigurationListResponse = await unitService.getLockTypeConfigurationsByLockType(lockTypeId);
        if ( !_.isNil(configurationsResponse)) {
          const activeConfigurations : LockTypeConfigurations[] = [];
          const configTypes: Array<ConfigurationType> = [];
          let optionalConfigsFilled = false;
          const configValues : {[key:string] : any} = {};
          const tempProtectedConfig : {[key:string] : boolean}= _.cloneDeep(protectedConfigValues);
          for (const lockConfigType of configurationsResponse.data) {
            if(lockConfigType.attributes.is_active !== true) continue //Do not display inactive config types
            const configType = configurationsResponse.included.find( ct => ct.id === lockConfigType.attributes.configuration_type_id )
            if(_.isNil(configType)) continue //Do not display config type deleted or not related to lock type
            activeConfigurations.push(lockConfigType);
            configTypes.push(configType.attributes);
            const accessConfigType = accessConfigurations.find(ac => ac.configuration_type_id === lockConfigType.attributes.configuration_type_id);
            if (!lockConfigType.attributes.always_required && !_.isNil(accessConfigType?.value)) optionalConfigsFilled = true;
            if (lockConfigType.attributes.always_required) configurationsRequired = true;
            if (_.isNil(accessConfigType?.value)) {
              configValues[configType.attributes.config_type_name] = {
                value: accessConfigType?.value ?? null,
                type: "text",
                disabled: false,
                customClass: AccessDetailStyles.textInput,
                icon: eye,
                id: accessConfigType?.id,
                is_protected: configType?.attributes.is_protected
              };
            } else {
              configValues[configType.attributes.config_type_name] = {
                value: accessConfigType?.value ?? null,
                type: configType?.attributes.is_protected ? "password" : "text",
                disabled: true,
                customClass: AccessDetailStyles.passwordInput,
                icon: configType?.attributes.is_protected ? eyeOff : eye,
                id: accessConfigType?.id,
                is_protected: configType?.attributes.is_protected
              };
            }
            if (configType?.attributes.is_protected) {
              tempProtectedConfig[configType?.attributes.config_type_name] = true;
            }
          }
          //TODO: Refactor to avoid using Relational ops (Like search through arrays for related values)- Use a Document/Obj aproach 
          setConfigurations(activeConfigurations);
          setConfigurationTypes(configTypes);
          setInputState(configValues);
          setSwitchState(optionalConfigsFilled);
          setProtectedConfigValues(tempProtectedConfig)
        }
      } catch (e) {
        console.error(e);
        addToast(`Error loading Unit Access information`, { appearance: "error" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleSwitchChange = (event: any) => {
    setSwitchState(event.target.checked);
    if (!event.target.checked) {
      accessConfigurations.map(c => {
        const lockTypeConfig = configurations.find(config => config.attributes.configuration_type_id === c.configuration_type_id);
        if (!lockTypeConfig?.attributes?.always_required)
          c.value = null;
      });
      setAccessConfigurations(accessConfigurations);
    } else {
      configurations.map(config => {
        if (!config?.attributes.always_required) {
          const configType = configurationTypes.find(c => c.id === config.attributes.configuration_type_id);
          updateProtectedConfig(configType?.config_type_name, false);
        }
      })
    }
  };

  const setValue = (name: string, configTypeId: number, event: any)  => {
    const position = accessConfigurations.findIndex(c => c.configuration_type_id === configTypeId);
    if (position === -1 ) { //if not exist
      const configType = configurationTypes.find(c => +c.id === +configTypeId);
      accessConfigurations.push({
        access_id: accessId,
        configuration_type_id: configTypeId,
        value: event.target.value,
        configuration_type: {
          config_type_name: configType.config_type_name
        }
      } as UnitAccessConfiguration);
    } else {
      accessConfigurations[position]["value"] = event.target.value;
    }
    setInputState({...inputState, [name]: {...inputState[name], ['value']: event.target.value}});
    setAccessConfigurations(accessConfigurations);
    if (protectedConfigValues[name]) {
      updateProtectedConfig(name, false);
    }
  }

  const showConfigurationsFields = () => {
    return (configurationTypes.map((config, pos) => {
      //get lockType-config record
      const lockTypeConfig = configurations.find(c => c.attributes.configuration_type_id === config.id);
      if (lockTypeConfig?.attributes?.always_required) { //adding required fields for validations
        addRequiredFields({
          fieldName: config.config_type_name,
          displayName: config.display_name,
          typeId: config.id
        });
      }
      return (
        <div className={`row ${AccessDetailStyles.customRow}`}>
          <div className="col-sm-4">
            <label>
              <strong>{config.display_name} </strong>
              {lockTypeConfig?.attributes?.always_required ? (
                <ThemeProvider theme={_default}>
                  <Required type={"error"} size={"small"} text={"*"}></Required>
                </ThemeProvider>
              ) : (
                <React.Fragment />
              )}
            </label>

          </div>
          {renderConfigurationInput(config, lockTypeConfig)}
        </div>
      )
    }));
  }

  const showConfigurationsFieldsByRequiredAttr = (required: boolean) => {
    return (configurationTypes.map((config, pos) => {
      //get lockType-config record
      const lockTypeConfig = configurations.find(c => c.attributes.configuration_type_id === config.id);
      if (lockTypeConfig?.attributes?.always_required === required) {
        if (required || (!required && switchState)) {
          if (required && lockTypeConfig?.attributes?.always_required) { //adding required fields for validations
            addRequiredFields({
              fieldName: config.config_type_name,
              displayName: config.display_name,
              typeId: config.id
            });
          }
          return (
            <div className={`row ${AccessDetailStyles.customRow}`}>
              <div className="col-sm-4">
                <label>
                  <strong>{config.display_name} </strong>
                  {lockTypeConfig?.attributes?.always_required ? (
                    <ThemeProvider theme={_default}>
                      <Required type={"error"} size={"small"} text={"*"}></Required>
                    </ThemeProvider>
                  ) : (
                    <React.Fragment />
                  )}
                </label>

              </div>
              {renderConfigurationInput(config, lockTypeConfig)}
            </div>
          )
        }
      }
    }));
  }

  const handleToggle = async (name: string) => {
    if (inputState[name].type === 'password') {
      if (protectedConfigValues[name] && !_.isNil(inputState[name].id)) { // reveal config value and update value to the real one
        const unmaskedConfig = await unitService.getAccessConfigurationById(+inputState[name].id, false);
        inputState[name].value = unmaskedConfig.attributes.value;
        updateProtectedConfig(name, false);
        // update input value with unprotected config value
        const accessConfigs = _.cloneDeep(accessConfigurations);
        const config = accessConfigs.find(c => c.id === +inputState[name].id);
        config.value = inputState[name].value;
        setAccessConfigurations(accessConfigs);
      }
      setInputState({
        ...inputState,
        [name]: { ...inputState[name], ['icon']: eye, ['type']: "text", ['disabled']: false, ['customClass']: AccessDetailStyles.textInput }
      });
    } else {
      setInputState({
        ...inputState,
        [name]: { ...inputState[name], ['icon']: eyeOff, ['type']: 'password', ['disabled']: true, ['customClass']: AccessDetailStyles.passwordInput}
      });
    }
  }

  /**
   * update protected status of the config
   */
  const updateProtectedConfig = (name: string, flag: boolean) => {
    const protectedClone = _.cloneDeep(protectedConfigValues);
    protectedClone[name] = flag;
    setProtectedConfigValues(protectedClone);
  }

  const hideValue = (value: string) => {
    let hiddenText = ''
    for (let char = 0; char < value.length; char++)
      hiddenText+= '•';
    return hiddenText;
  }

  const renderConfigurationInput = (config: ConfigurationType, lockTypeConfig: LockTypeConfigurations | undefined) => {
    //get access-config record
    switch (config.value_type) {
      case "string": {
        return (
          <div className={`col-sm-8 ${AccessDetailStyles.configurationCol}`}>
          {inputState[config.config_type_name].type === 'password' ? (
              <label className={AccessDetailStyles.passwordInput}>{hideValue(inputState[config.config_type_name].value ?? lockTypeConfig?.attributes?.default_value ?? "")}</label>
            ) : (
                <input
                  type={inputState[config.config_type_name].type}
                  name={config.config_type_name}
                  value={inputState[config.config_type_name].value ?? lockTypeConfig?.attributes?.default_value ?? ""}
                  onChange={(newValue)=> setValue(config.config_type_name, config.id, newValue)}
                  disabled={config.readonly ? true : inputState[config.config_type_name].disabled}
                  className={`${AccessDetailStyles.inputLeft} ${inputState[config.config_type_name].customClass}`}
                />
            )}
            <button type="button" className={`${AccessDetailStyles.icon} ${AccessDetailStyles.notButton}`} onClick={() => handleToggle(config.config_type_name)}>
              {inputState[config.config_type_name].icon}
            </button>
          </div>
        )
        break;
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="row">
          <div className={`col centerContent}`}>
            <h6>Loading data...</h6>
          </div>
        </div>
      ) : (
        <div>
          {showConfigurationsFields()}
        </div>
      )}
    </React.Fragment>)
}